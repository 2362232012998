import React, { Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { InventoryTrimSummaryEntities } from 'client/data/models/inventory-core-configurations';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { getTaxCreditAmountPath, IncentiveModel } from 'client/data/models/incentives';
import { getDealsUrl } from 'site-modules/shared/utils/core-link-constructor';
import { withTargetText } from 'site-modules/shared/utils/url-utils';
import { INCENTIVE_TYPES } from 'site-modules/shared/constants/incentives';
import { Link } from 'site-modules/shared/components/link/link';
import { TooltipTextItem } from 'site-modules/shared/components/tooltip-text-item/tooltip-text-item';
import { EDMUNDS_LOGO_TEXT } from 'client/images/inline-svgs/edmunds-logo-text';

const CREATIVE_ID = { CTA: 'edm-entry-model-summary-cta', SUMMARY: 'edm-entry-model-summary' };
const TOOLTIP_VALUE_MAP = { 'ev-tax-rebate': 'Federal EV Tax Credit', 'market-price': 'Edmunds Market Price' };

export function TrimsCtaSimpleSummaryUI({
  className,
  selectedTrim,
  taxRebateAmount,
  vehicleName,
  vehicleParams,
  ctaProps,
  showLeadForm,
  showTaxRebates,
  isDealerlessOem,
  isMsrpStrikethrough,
  core5945Recipe,
  isCore6148Enabled,
}) {
  const onTooltipOpen = useCallback(
    ({ id }) =>
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_VIEW_CONTENT,
          subaction_name: TrackingConstant.VIEW_TOOLTIP,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: CREATIVE_ID.SUMMARY,
          value: TOOLTIP_VALUE_MAP[id],
        },
      }),
    []
  );

  const ctaText = get(ctaProps, 'children');
  useEffect(() => {
    if (!ctaText) {
      return;
    }

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: 'trim_summary_cta_text',
        creative_id: CREATIVE_ID.CTA,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        value: ctaText,
      },
    });
  }, [ctaText]);

  useEffect(() => {
    if (taxRebateAmount === null) {
      return;
    }

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: 'federal_ev_tax_credit',
        creative_id: CREATIVE_ID.SUMMARY,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        value: taxRebateAmount ? `Up to ${getPriceString(taxRebateAmount)}` : 'not eligible',
      },
    });
  }, [taxRebateAmount]);

  const totalMsrp = get(selectedTrim, 'pricing.totalMsrp');
  const ESP = get(selectedTrim, 'pricing.suggestedPrice', totalMsrp);
  let espLabel = ESP > totalMsrp ? 'What others are paying' : 'Edmunds suggests you pay';

  if (['chal1', 'chal2'].includes(core5945Recipe)) {
    espLabel = (
      <TooltipTextItem
        id="market-price"
        buttonClassName={classnames('text-decoration-none p-0', {
          'logo-tooltip-btn d-flex flex-wrap align-items-center': core5945Recipe === 'chal2',
          'esp-tooltip-btn text-left': core5945Recipe === 'chal1',
        })}
        item={{
          text: isDealerlessOem
            ? `The ${vehicleName} has a price set by the manufacturer that is not negotiable. In this case, the Edmunds Market Price is MSRP minus any incentives & rebates.`
            : 'The Edmunds Market Price is our best estimate of what you can expect to pay for this vehicle, including destination fee but excluding options, taxes, and other fees. It’s based on our analysis of millions of data points, including supply, demand, incentives, and recent nearby transactions.',
          placement: 'top',
        }}
        onTooltipOpen={onTooltipOpen}
      >
        {core5945Recipe === 'chal2'
          ? EDMUNDS_LOGO_TEXT({
              className: 'pr-0_25 mr-0_25',
              'aria-label': 'Edmunds',
              fillColor: '#2a3546',
            })
          : 'Edmunds'}{' '}
        <span className="text-nowrap pos-r">
          Market Price
          <sup className="pos-a" style={{ right: '-1rem', top: '-0.25em' }}>
            <i className="icon-info small" aria-hidden />
          </sup>
        </span>
      </TooltipTextItem>
    );
  }

  const totalInventory = get(selectedTrim, 'inventoryCount');
  const buttonProps = {
    ...ctaProps,
    size: 'lg',
    color: 'blue-50',
    className: 'cta-button rounded-8 w-100 py-0_75 text-transform-none text-white',
    'data-hidedelightedoninteraction': true,
  };

  const evTaxRebateTooltip = (
    <TooltipItem
      id="ev-tax-rebate"
      className="top-0"
      iconClassName="small text-primary-darker"
      innerClassName="p-1"
      item={{
        placement: 'bottom',
        text: taxRebateAmount ? (
          <Fragment>
            Tax credit eligibility subject to income limits.{' '}
            <Link
              to={withTargetText(
                getDealsUrl(vehicleParams, {
                  styleId: get(selectedTrim, 'styleId'),
                  section: INCENTIVE_TYPES.OTHER,
                }),
                { text: 'federal credit' }
              )}
              className="text-primary-darker"
              data-tracking-id="view_ev_tax_credit"
              target="_blank"
            >
              Learn more
            </Link>
          </Fragment>
        ) : (
          `The ${get(selectedTrim, 'trimName', '')} trim does not qualify for the Clean Vehicle Credit.`
        ),
      }}
      delay={{ show: 0, hide: 250 }}
      autohide={false}
      onTooltipOpen={onTooltipOpen}
    />
  );

  return (
    <div className={classnames('trim-simple-summary size-16 w-100', className)} data-nosnippet>
      {isCore6148Enabled && (
        <table className="w-100 text-gray-darker">
          <tbody>
            <tr>
              <th
                scope="row"
                className={classnames('text-left align-middle font-weight-normal pos-r px-0', {
                  'pb-0_75': !!ESP,
                })}
              >
                Total MSRP
              </th>
              <td
                className={classnames('text-right align-top px-0', {
                  strikethrough: isMsrpStrikethrough,
                })}
              >
                {getPriceString(totalMsrp)}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                className={classnames('text-left align-middle pl-0 font-weight-normal', {
                  'pr-0': core5945Recipe !== 'chal2',
                  'pr-1': core5945Recipe === 'chal2',
                })}
              >
                {espLabel}
              </th>
              <td className="text-right px-0 align-top">{getPriceString(ESP)}</td>
            </tr>
            {ESP > totalMsrp && (
              <tr>
                <td colSpan={2} className="small text-gray-dark pt-0_5">
                  Low supply is pushing the market average above MSRP.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <div className="px-0_25">
        {!isCore6148Enabled && (
          <div className="d-flex flex-column align-items-center justify-content-between">
            <div className="font-weight-medium mb-0_25">{espLabel}</div>
            <strong className="heading-2">{getPriceString(ESP)}</strong>
          </div>
        )}
        {showTaxRebates && (
          <Fragment>
            <div className="text-center mt-1 medium text-cool-gray-40">
              <span className="font-weight-bold">
                <i className="icon-power2 text-turquoise-30" aria-hidden /> Federal EV Tax Credit:
              </span>
              <span className="font-weight-medium">
                {taxRebateAmount !== null &&
                  (taxRebateAmount ? ` up to ${getPriceString(taxRebateAmount)}` : ' not eligible')}
              </span>
              <span className="text-nowrap">
                &nbsp;
                {evTaxRebateTooltip}
              </span>
            </div>
          </Fragment>
        )}
        {!isCore6148Enabled && ESP > totalMsrp && (
          <div
            className={classnames('small text-cool-gray-40 text-center', {
              'mt-0_25': !showTaxRebates,
              'mt-1': showTaxRebates,
            })}
          >
            Low supply is pushing the market average above MSRP.
          </div>
        )}
      </div>
      {!!ctaProps && (
        <Fragment>
          {showLeadForm ? (
            <Fragment>
              <Button
                {...buttonProps}
                className={classnames('mt-2', buttonProps.className)}
                data-tracking-parent={CREATIVE_ID.CTA}
              />
              {!!totalInventory && (
                <div className="small font-italic mt-0_25 text-blue-40">{totalInventory} for sale near you</div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {isDealerlessOem && (
                <div className="text-left text-gray-darker mb-1 mt-2">
                  Choose the trim, color, options, packages and more for your {vehicleName}.
                </div>
              )}
              <Button
                {...buttonProps}
                className={classnames(buttonProps.className, {
                  'mt-2': !isDealerlessOem,
                })}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}

TrimsCtaSimpleSummaryUI.propTypes = {
  className: PropTypes.string,
  ctaProps: PropTypes.shape({
    onClick: PropTypes.func,
    to: PropTypes.string,
    rel: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    'data-tracking-id': PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  selectedTrim: InventoryTrimSummaryEntities.TrimSummary.isRequired,
  vehicleName: PropTypes.string.isRequired,
  vehicleParams: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    year: PropTypes.string,
  }).isRequired,
  taxRebateAmount: PropTypes.number,
  srpLink: PropTypes.string,
  showLeadForm: PropTypes.bool,
  showTaxRebates: PropTypes.bool,
  isDealerlessOem: PropTypes.bool,
  isMobile: PropTypes.bool,
  isMsrpStrikethrough: PropTypes.bool,
  isCore6148Enabled: PropTypes.bool,
  core5945Recipe: PropTypes.string,
};

TrimsCtaSimpleSummaryUI.defaultProps = {
  className: null,
  srpLink: null,
  taxRebateAmount: null,
  ctaProps: null,
  showLeadForm: false,
  showTaxRebates: false,
  isDealerlessOem: false,
  isMobile: false,
  isMsrpStrikethrough: false,
  isCore6148Enabled: false,
  core5945Recipe: 'ctrl',
};

export const propsAreEqual = (prevProps, { showTaxRebates, taxRebateAmount }) =>
  showTaxRebates && taxRebateAmount === null;
export const TrimsCtaSimpleSummary = connectToModel(React.memo(TrimsCtaSimpleSummaryUI, propsAreEqual), {
  taxRebateAmount: bindToPath(
    ({ selectedTrim, showTaxRebates }) => showTaxRebates && getTaxCreditAmountPath(selectedTrim.styleId),
    IncentiveModel
  ),
});
